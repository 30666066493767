//!
// _scrollTop.scss
//
//# =================================================================
//# 抽象クラス定義
//# =================================================================
%extend_01 {
  color: map-get($theme_colors, "wht");
  margin: auto;
  text-align: center;
}
/*
# =================================================================
# Topへ戻る-共通ボタン定義
# =================================================================
*/
// #scroll-top ボタンは円形を採用
#scroll-top {
  @include fixed(
    $positions: (
      null,
      5px,
      5px,
      null
    )
  );
  background: map-get($theme_colors, "org");
  opacity: 0.5;
  border-radius: 50%;
  transition: $transition;
  @include mediaQuery() {
    @include positions(null, 10px, 50px, null);
  }
  // #scroll-top a
  a {
    display: block;
    @include relative(
      $size: (
        60px,
        60px
      )
    );
    @include mediaQuery() {
      @include size(90px, 90px);
    }
    // #scroll-top a::before
    // 上部半円の中央ぐらいに上矢印を描写
    &::before {
      content: map-get($font_awesome, "angle-double-up");
      font: {
        family: "Font Awesome 5 Free";
        weight: 900;
        size: 2.5rem;
      }
      @include absolute(
        $positions: (
          -40px,
          0,
          0,
          0
        ),
        $size: (
          15px,
          15px
        )
      );
      @include mediaQuery() {
        @include size(25px, 25px);
      }
      @extend %extend_01;
    }
    // #scroll-top a::after
    // 下部半円の中央ぐらいに"PAGE TOP"を描写
    &::after {
      content: "PAGE TOP";
      font-size: 1rem;
      @include absolute(
        $positions: (
          35px,
          0,
          0,
          0
        )
      );
      @include mediaQuery() {
        @include positions(45px, null, null, null);
      }
      @extend %extend_01;
    }
  }
  //#scroll-top:hover , #scroll-top:focus
  &:hover,
  &:focus {
    opacity: 0.8;
  }
  // #scroll-top.hide
  // hideクラスが付与された場合、ヘッダを画面外に移動して隠す
  &.hide {
    @include mediaQuery(large) {
      transform: translate(0, 150px);
    }
  }
}
