//!
// _footer.scss
//
//# =================================================================
//# import scss
//# =================================================================
@import "../config/const";
@import "../config/mixin";
@import "../config/extend";
//# =================================================================
//# 共通フッダ定義
//# =================================================================
footer {
  @include relative(
    $positions: (
      null,
      null,
      0,
      null
    ),
    $size: (
      50px,
      100%
    )
  );
  background: map-get($theme_colors, "gry");
  z-index: -1;
  * {
    color: map-get($theme_colors, "wht");
  }
}
