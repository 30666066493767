//!
// _header.scss
//
//# =================================================================
//# import scss
//# =================================================================
@import "../config/const";
@import "../config/mixin";
@import "../config/extend";
//# =================================================================
//# 変数定義
//# =================================================================
$hamburger_span: 1.5px;
$hamburger_size: 25px;
$space: 5px;
$transforms: (
  translateX: 0px,
  translateY: 6px,
  rotate: 45deg
);
$posNames: (
  top: top,
  mid: null,
  btm: bottom
);
//# =================================================================
//# アニメーション定義
//# =================================================================
@each $pos, $anime in $posNames {
  $translateY: map-get($transforms, "translateY");
  $rotate: map-get($transforms, "rotate");
  $translateY_close: $hamburger_span * -1;
  @if ($anime == top) {
    $translateY: $translateY * -1;
    $rotate: $rotate * -1;
    $translateY_close: $translateY_close * -1;
  }
  @if $anime != null {
    @keyframes #{$anime} {
      0% {
        transform: translateY(0px) rotate($rotate);
      }
      50% {
        transform: translateY(0px) rotate(0deg);
      }
      100% {
        transform: translateY($translateY) rotate(0deg);
      }
    }
    @keyframes #{$anime}-close {
      0% {
        transform: translateY($translateY) rotate(0deg);
      }
      50% {
        transform: translateY(0px) rotate(0deg);
      }
      100% {
        transform: translateY($translateY_close) rotate($rotate);
      }
    }
    @include mediaQuery() {
      @keyframes #{$anime} {
        0% {
          transform: translateY(0px) rotate($rotate);
        }
        50% {
          transform: translateY(0px) rotate(0deg);
        }
        100% {
          transform: translateY($translateY * 2) rotate(0deg);
        }
      }
      @keyframes #{$anime}-close {
        0% {
          transform: translateY($translateY * 2) rotate(0deg);
        }
        50% {
          transform: translateY(0px) rotate(0deg);
        }
        100% {
          transform: translateY($translateY_close * 2) rotate($rotate);
        }
      }
    }
  }
}
//# =================================================================
//# 共通ヘッダ定義
//# =================================================================
#cmn-header {
  @include fixed(
    $positions: (
      0,
      null,
      null,
      0
    ),
    $size: (
      #{$hamburger_size + $space * 2},
      100%
    )
  );
  z-index: 999;
  transition: $transition;
  @include mediaQuery() {
    @include size(#{($hamburger_size * 2) + ($space * 2) * 2}, null);
  }
  * {
    color: map-get($theme_colors, "wht");
    transition: $transition;
    text: {
      align: center;
      decoration: none;
    }
    list-style: none;
    padding: 0;
  }
  img {
    @include size(#{$hamburger_size + $space * 2}, auto);
    -ms-interpolation-mode: bicubic;
    display: block;
    padding: $space 0;
    @include mediaQuery() {
      @include size(#{$hamburger_size * 2}, auto);
      padding: #{$space * 2} 0;
    }
  }
  #hamburger {
    @include absolute(
      $positions: (
        0,
        0,
        null,
        null
      ),
      $size: (
        $hamburger_size,
        $hamburger_size
      )
    );
    cursor: pointer;
    margin: $space;
    z-index: 999;
    @include mediaQuery() {
      @include size(#{$hamburger_size * 2}, #{$hamburger_size * 2});
      margin: #{$space * 2};
    }
    span {
      border-radius: 5px;
      @each $pos, $anime in $posNames {
        &.#{$pos} {
          @include relative(
            $size: (
              $hamburger_span,
              100%
            )
          );
          background: map-get($theme_colors, "wht");
          transition: $transition opacity;
          @if $anime != null {
            animation: $anime 0.5s;
            animation-fill-mode: forwards;
          }
          @include mediaQuery() {
            @include size(#{$hamburger_span * 2}, null);
          }
        }
      }
    }
  }
  #main-nav {
    @include relative(
      $size: (
        100%,
        100vw
      )
    );
    background: map-get($theme_colors, "org");

    // #main-nav ul
    ul {
      &.menu_1st-lv {
        @include relative(
          $positions: (
            #{$hamburger_size + $space * 2},
            null,
            null,
            null
          )
        );
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: map-get($theme_colors, "org");
        transform: translateY(#{($hamburger_size * 2 * 4) * -1});
        z-index: -1;
        opacity: 0;
        @include mediaQuery() {
          @include relative(
            $positions: (
              #{($hamburger_size * 2) + ($space * 2) * 2},
              null,
              null,
              null
            )
          );
          flex-direction: row;
          transform: translateY(#{($hamburger_size * 2) * -1});
        }
        li {
          line-height: #{$hamburger_size * 2};
          border-top: map-get($theme_colors, "brz") 1px solid;
          > a,
          span {
            @include size(100%, 100%);
            padding: 0 $space;
          }
          > i {
            padding-left: $space;
          }
          // #cmn-main-nav ul.menu_1st-lv li:hover
          &:hover {
            background: rgba(map-get($theme_colors, "blk"), 1);
            // #cmn-main-nav ul.menu_1st-lv li:hover > a
            > a,
            span {
              color: rgba(map-get($theme_colors, "org"), 1);
            }
            > i {
              color: rgba(map-get($theme_colors, "org"), 1);
              transform: rotate(90deg);
            }
          }
        }
        > li {
          @include mediaQuery() {
            @include size(#{$hamburger_size * 2}, 15%);
          }
        }
      }
      &.menu_2nd-lv {
        @include relative(
          $size: (
            null,
            100%
          )
        );
        display: none;
        transition: none;
        background: rgba(map-get($theme_colors, "blk"), 0.7)
          url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAYAAACddGYaAAAAD0lEQVQIW2NkQABjRmQOAAM+AGkQsDBSAAAAAElFTkSuQmCC)
          repeat;
      }
    }
  }
  // #cmn-header.hide
  // hideクラスが付与された場合、ヘッダを画面外に移動して隠す
  &.hide {
    transform: translate(0, #{($hamburger_size + $space * 2) * -1});
    @include mediaQuery() {
      transform: translate(
        0,
        #{(($hamburger_size * 2) + ($space * 2) * 2) * -1}
      );
    }
  }
  &.is-open {
    #hamburger {
      span {
        @each $pos, $anime in $posNames {
          &.#{$pos} {
            background: map-get($theme_colors, "wht");
            @if $anime != null {
              animation: #{$anime}-close 0.5s;
              animation-fill-mode: forwards;
            } @else {
              opacity: 0;
            }
          }
        }
      }
    }
    #main-nav {
      ul {
        &.menu_1st-lv {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }
}
