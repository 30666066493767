//!
// _mixin.scss
//!

//# =================================================================
//#  使用するscssファイルにimportして使用する
//#  Author : K.Satou
//#  update : 19.mm.dd 初版更改
//# =================================================================
//# =================================================================
//# font-face : Webフォント使用設定
//# =================================================================
@mixin font-face($name, $path, $weight: null, $style: null, $exts: woff) {
  $src: null;
  @each $ext in $exts {
    $format: map-get($formats, $ext);
    $src: append($src, url(quote($path)) format(quote($format)), comma);
  }
  @font-face {
    font: {
      family: quote($name);
      style: $style;
      weight: $weight;
    }
    src: $src;
  }
}
//# =================================================================
//# justify : 両端揃えの設定
//# =================================================================
@mixin justify() {
  text-align: justify;
  text-justify: inter-ideograph;
}
//# =================================================================
//# line-truncate : 文字が溢れた際の表示を … にする
//# =================================================================
@mixin line-truncate() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
//# =================================================================
//# relative : position:relative;
//             および height / width を一括設定
//# =================================================================
@mixin relative(
  $positions: (
    null,
    null,
    null,
    null
  ),
  $size: (
    null,
    null
  )
) {
  position: relative;
  $top: nth($positions, 1);
  $right: nth($positions, 2);
  $bottom: nth($positions, 3);
  $left: nth($positions, 4);
  $height: nth($size, 1);
  $width: nth($size, 2);
  @include positions($top, $right, $bottom, $left);
  @include size($height, $width);
}
//# =================================================================
//# absolute : position:absolute;
//             および top / right / bottom / left
//             および height / width を一括設定
//# =================================================================
@mixin absolute(
  $positions: (
    null,
    null,
    null,
    null
  ),
  $size: (
    null,
    null
  )
) {
  position: absolute;
  $top: nth($positions, 1);
  $right: nth($positions, 2);
  $bottom: nth($positions, 3);
  $left: nth($positions, 4);
  $height: nth($size, 1);
  $width: nth($size, 2);
  @include positions($top, $right, $bottom, $left);
  @include size($height, $width);
}
//# =================================================================
//# fixed    : position:fixed;
//             および top / right / bottom / left
//             および height / width を一括設定
//# =================================================================
@mixin fixed(
  $positions: (
    null,
    null,
    null,
    null
  ),
  $size: (
    null,
    null
  )
) {
  position: fixed;
  $top: nth($positions, 1);
  $right: nth($positions, 2);
  $bottom: nth($positions, 3);
  $left: nth($positions, 4);
  $height: nth($size, 1);
  $width: nth($size, 2);
  @include positions($top, $right, $bottom, $left);
  @include size($height, $width);
}
//# =================================================================
//# grid     : display:grid;
//             gap / grid-template-columns / grid-template-rows /
//             height / width を一括設定
//# =================================================================
@mixin grid(
  $gap: null,
  $col: (),
  $row: (),
  $size: (
    null,
    null
  )
) {
  display: grid;
  @if $gap != null {
    grid-gap: $gap;
  }
  $len: length($col);
  @if $len > 0 {
    $columns: null;
    @for $i from 1 through $len {
      $columns: append($columns, nth($col, $i));
    }
    grid-template-columns: $columns;
  }
  $len: length($row);
  @if $len > 0 {
    $rows: null;
    @for $i from 1 through $len {
      $rows: append($rows, nth($row, $i));
    }
    grid-template-rows: $rows;
  }
  $height: nth($size, 1);
  $width: nth($size, 2);
  @include size($height, $width);
}
//# =================================================================
//# positions : top / right / bottom / left の設定を一括設定
//# =================================================================
@mixin positions($top: null, $right: null, $bottom: null, $left: null) {
  @if $top != null {
    top: $top;
  }
  @if $right != null {
    right: $right;
  }
  @if $bottom != null {
    bottom: $bottom;
  }
  @if $left != null {
    left: $left;
  }
}
//# =================================================================
//# size : height / width の設定を一括設定
//# =================================================================
@mixin size($height: null, $width: null) {
  @if $height != null {
    height: $height;
  }
  @if $width != null {
    width: $width;
  }
}
//# =================================================================
//# mediaQuery : @media screen を設定
//# =================================================================
@mixin mediaQuery($break: medium, $minmax: min) {
  $screen: null;
  $query: null;
  @if ($break == portrait) or ($break == landscape) {
    $screen: "orientation:" + map-get($break_points, $break);
  } @else if ($minmax == min) {
    $calc: map-get($break_points, $break);
    $screen: "min-width:" + $calc + "px";
  } @else {
    $calc: map-get($break_points, $break) - 1;
    $screen: "max-width:" + $calc + "px";
  }
  $query: "screen and (" + $screen + ")";
  @media #{$query} {
    @content;
  }
}
