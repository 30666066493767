@charset "UTF-8";
/*
!! _extend.scss
*/
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@keyframes top {
  0% {
    transform: translateY(0px) rotate(-45deg);
  }
  50% {
    transform: translateY(0px) rotate(0deg);
  }
  100% {
    transform: translateY(-6px) rotate(0deg);
  }
}

@keyframes top-close {
  0% {
    transform: translateY(-6px) rotate(0deg);
  }
  50% {
    transform: translateY(0px) rotate(0deg);
  }
  100% {
    transform: translateY(1.5px) rotate(-45deg);
  }
}

@media screen and (min-width: 768px) {
  @keyframes top {
    0% {
      transform: translateY(0px) rotate(-45deg);
    }
    50% {
      transform: translateY(0px) rotate(0deg);
    }
    100% {
      transform: translateY(-12px) rotate(0deg);
    }
  }
  @keyframes top-close {
    0% {
      transform: translateY(-12px) rotate(0deg);
    }
    50% {
      transform: translateY(0px) rotate(0deg);
    }
    100% {
      transform: translateY(3px) rotate(-45deg);
    }
  }
}

@keyframes bottom {
  0% {
    transform: translateY(0px) rotate(45deg);
  }
  50% {
    transform: translateY(0px) rotate(0deg);
  }
  100% {
    transform: translateY(6px) rotate(0deg);
  }
}

@keyframes bottom-close {
  0% {
    transform: translateY(6px) rotate(0deg);
  }
  50% {
    transform: translateY(0px) rotate(0deg);
  }
  100% {
    transform: translateY(-1.5px) rotate(45deg);
  }
}

@media screen and (min-width: 768px) {
  @keyframes bottom {
    0% {
      transform: translateY(0px) rotate(45deg);
    }
    50% {
      transform: translateY(0px) rotate(0deg);
    }
    100% {
      transform: translateY(12px) rotate(0deg);
    }
  }
  @keyframes bottom-close {
    0% {
      transform: translateY(12px) rotate(0deg);
    }
    50% {
      transform: translateY(0px) rotate(0deg);
    }
    100% {
      transform: translateY(-3px) rotate(45deg);
    }
  }
}

#cmn-header {
  position: fixed;
  top: 0;
  left: 0;
  height: 35px;
  width: 100%;
  z-index: 999;
  transition: all 0.5s ease;
}

@media screen and (min-width: 768px) {
  #cmn-header {
    height: 70px;
  }
}

#cmn-header * {
  color: #ffffff;
  transition: all 0.5s ease;
  text-align: center;
  text-decoration: none;
  list-style: none;
  padding: 0;
}

#cmn-header img {
  height: 35px;
  width: auto;
  -ms-interpolation-mode: bicubic;
  display: block;
  padding: 5px 0;
}

@media screen and (min-width: 768px) {
  #cmn-header img {
    height: 50px;
    width: auto;
    padding: 10px 0;
  }
}

#cmn-header #hamburger {
  position: absolute;
  top: 0;
  right: 0;
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin: 5px;
  z-index: 999;
}

@media screen and (min-width: 768px) {
  #cmn-header #hamburger {
    height: 50px;
    width: 50px;
    margin: 10px;
  }
}

#cmn-header #hamburger span {
  border-radius: 5px;
}

#cmn-header #hamburger span.top {
  position: relative;
  height: 1.5px;
  width: 100%;
  background: #ffffff;
  transition: all 0.5s ease opacity;
  animation: top 0.5s;
  animation-fill-mode: forwards;
}

@media screen and (min-width: 768px) {
  #cmn-header #hamburger span.top {
    height: 3px;
  }
}

#cmn-header #hamburger span.mid {
  position: relative;
  height: 1.5px;
  width: 100%;
  background: #ffffff;
  transition: all 0.5s ease opacity;
}

@media screen and (min-width: 768px) {
  #cmn-header #hamburger span.mid {
    height: 3px;
  }
}

#cmn-header #hamburger span.btm {
  position: relative;
  height: 1.5px;
  width: 100%;
  background: #ffffff;
  transition: all 0.5s ease opacity;
  animation: bottom 0.5s;
  animation-fill-mode: forwards;
}

@media screen and (min-width: 768px) {
  #cmn-header #hamburger span.btm {
    height: 3px;
  }
}

#cmn-header #main-nav {
  position: relative;
  height: 100%;
  width: 100vw;
  background: #f04400;
}

#cmn-header #main-nav ul.menu_1st-lv {
  position: relative;
  top: 35px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #f04400;
  transform: translateY(-200px);
  z-index: -1;
  opacity: 0;
}

@media screen and (min-width: 768px) {
  #cmn-header #main-nav ul.menu_1st-lv {
    position: relative;
    top: 70px;
    flex-direction: row;
    transform: translateY(-50px);
  }
}

#cmn-header #main-nav ul.menu_1st-lv li {
  line-height: 50px;
  border-top: #4d1600 1px solid;
}

#cmn-header #main-nav ul.menu_1st-lv li > a,
#cmn-header #main-nav ul.menu_1st-lv li span {
  height: 100%;
  width: 100%;
  padding: 0 5px;
}

#cmn-header #main-nav ul.menu_1st-lv li > i {
  padding-left: 5px;
}

#cmn-header #main-nav ul.menu_1st-lv li:hover {
  background: black;
}

#cmn-header #main-nav ul.menu_1st-lv li:hover > a,
#cmn-header #main-nav ul.menu_1st-lv li:hover span {
  color: #f04400;
}

#cmn-header #main-nav ul.menu_1st-lv li:hover > i {
  color: #f04400;
  transform: rotate(90deg);
}

@media screen and (min-width: 768px) {
  #cmn-header #main-nav ul.menu_1st-lv > li {
    height: 50px;
    width: 15%;
  }
}

#cmn-header #main-nav ul.menu_2nd-lv {
  position: relative;
  width: 100%;
  display: none;
  transition: none;
  background: rgba(0, 0, 0, 0.7) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAYAAACddGYaAAAAD0lEQVQIW2NkQABjRmQOAAM+AGkQsDBSAAAAAElFTkSuQmCC) repeat;
}

#cmn-header.hide {
  transform: translate(0, -35px);
}

@media screen and (min-width: 768px) {
  #cmn-header.hide {
    transform: translate(0, -70px);
  }
}

#cmn-header.is-open #hamburger span.top {
  background: #ffffff;
  animation: top-close 0.5s;
  animation-fill-mode: forwards;
}

#cmn-header.is-open #hamburger span.mid {
  background: #ffffff;
  opacity: 0;
}

#cmn-header.is-open #hamburger span.btm {
  background: #ffffff;
  animation: bottom-close 0.5s;
  animation-fill-mode: forwards;
}

#cmn-header.is-open #main-nav ul.menu_1st-lv {
  transform: translateY(0);
  opacity: 1;
}

/*
!! _extend.scss
*/
footer {
  position: relative;
  bottom: 0;
  height: 50px;
  width: 100%;
  background: #333132;
  z-index: -1;
}

footer * {
  color: #ffffff;
}

#scroll-top a::before, #scroll-top a::after {
  color: #ffffff;
  margin: auto;
  text-align: center;
}

/*
# =================================================================
# Topへ戻る-共通ボタン定義
# =================================================================
*/
#scroll-top {
  position: fixed;
  right: 5px;
  bottom: 5px;
  background: #f04400;
  opacity: 0.5;
  border-radius: 50%;
  transition: all 0.5s ease;
}

@media screen and (min-width: 768px) {
  #scroll-top {
    right: 10px;
    bottom: 50px;
  }
}

#scroll-top a {
  display: block;
  position: relative;
  height: 60px;
  width: 60px;
}

@media screen and (min-width: 768px) {
  #scroll-top a {
    height: 90px;
    width: 90px;
  }
}

#scroll-top a::before {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 2.5rem;
  position: absolute;
  top: -40px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 15px;
  width: 15px;
}

@media screen and (min-width: 768px) {
  #scroll-top a::before {
    height: 25px;
    width: 25px;
  }
}

#scroll-top a::after {
  content: "PAGE TOP";
  font-size: 1rem;
  position: absolute;
  top: 35px;
  right: 0;
  bottom: 0;
  left: 0;
}

@media screen and (min-width: 768px) {
  #scroll-top a::after {
    top: 45px;
  }
}

#scroll-top:hover, #scroll-top:focus {
  opacity: 0.8;
}

@media screen and (min-width: 1024px) {
  #scroll-top.hide {
    transform: translate(0, 150px);
  }
}

/*
# =================================================================
# Webフォントの設定
# =================================================================
*/
/*
@include font-face(
  $name: "Roboto",
  $path: "https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBBc4.woff2",
  $weight: 300,
  $style: normal,
  $exts: woff2
);

@include font-face(
  $name: "Roboto",
  $path: "https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxK.woff2)",
  $weight: 500,
  $style: normal,
  $exts: woff2
);

@include font-face(
  $name: "Roboto",
  $path: "https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxK.woff2)",
  $weight: 700,
  $style: normal,
  $exts: woff2
);
*/
/*
# =================================================================
# HTMLタグ　共通設定
# =================================================================
*/
* {
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  color: #000000;
  font-size: 62.5%;
}

body {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "游ゴシック体", YuGothic, "Yu Gothic Medium", "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
  font-size: calc(1rem + 0.5vw);
  font-feature-settings: "pkna" 1;
  background: none;
}

body.preload * {
  transition: none !important;
}

img {
  -ms-interpolation-mode: bicubic;
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.2em;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
ul,
li,
p,
th,
td,
pre {
  padding: calc(0.5rem + 0.25vw) 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "游ゴシック体", YuGothic, "Yu Gothic", "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
  font-weight: bold;
}

pre {
  white-space: pre-wrap;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "游ゴシック体", YuGothic, "Yu Gothic", "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
}

section::before, section::after,
article::before,
article::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

a,
ul,
p,
span {
  text-decoration: none;
}

p {
  word-wrap: break-word;
}

/*
# =================================================================
# デザイン共通設定
# =================================================================
*/
main.is-open {
  opacity: 0.1;
}

main.is-blur {
  opacity: 0.6;
  filter: blur(6px);
}

.cmn-bg-wht {
  background-color: #ffffff;
}

.cmn-bg-org {
  background-color: #f04400;
}

.cmn-bg-blk {
  background-color: #000000;
}

.cmn-bg-border {
  background: rgba(0, 0, 0, 0.7) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAYAAACddGYaAAAAD0lEQVQIW2NkQABjRmQOAAM+AGkQsDBSAAAAAElFTkSuQmCC) repeat;
}

.cmn-screen-full {
  position: relative;
  height: 100vh;
  width: 100%;
}

.cmn-screen-harf {
  position: relative;
  height: 50vh;
  width: 100%;
}

.cmn-screen {
  position: relative;
  width: 100%;
}

.cmn-left {
  text-align: left;
}

.cmn-right {
  text-align: right;
}

.cmn-center {
  text-align: center;
}

.cmn-container {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* 子要素をflexboxにより縦方向に揃える */
  justify-content: center;
  /* 子要素をflexboxにより中央に配置する */
  align-items: center;
  /* 子要素をflexboxにより中央に配置する */
}

.cmn-tags-right {
  background: #f04400;
  border-radius: 8px 0 0 8px;
  color: #ffffff;
  display: inline-block;
  position: relative;
  height: 1.5em;
  line-height: 1.5em;
  padding: 0 2em 0 3em;
}

.cmn-tags-right::before {
  content: "";
  border: 0.75em solid transparent;
  border-left-color: #f04400;
  border-right-width: 0;
  display: block;
  position: absolute;
  top: 0;
  right: -0.72em;
  height: 0;
  width: 0;
}

.cmn-tags-right::after {
  content: "";
  background: #4d1600;
  border-radius: 8px 0 0 8px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 1em;
}

.cmn-tags-left {
  background: #f04400;
  border-radius: 0 8px 8px 0;
  color: #ffffff;
  display: inline-block;
  position: relative;
  height: 1.5em;
  line-height: 1.5em;
  padding: 0 3em 0 2em;
}

.cmn-tags-left::before {
  content: "";
  border: 0.75em solid transparent;
  border-right-color: #f04400;
  border-left-width: 0;
  display: block;
  position: absolute;
  top: 0;
  left: -0.72em;
  height: 0;
  width: 0;
}

.cmn-tags-left::after {
  content: "";
  background: #4d1600;
  border-radius: 0 8px 8px 0;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 1em;
}
