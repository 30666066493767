//!
// type01.comonn.css
//!
//# =================================================================
//# import scss
//# =================================================================
@import "config/const";
@import "config/mixin";
@import "module/header";
@import "module/footer";
@import "module/scrollTop";
//# =================================================================
//# 変数定義
//# =================================================================
$tags_height: 1.5em;
$tags_height_harf: $tags_height / 2;
/*
# =================================================================
# Webフォントの設定
# =================================================================
*/
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
/*
@include font-face(
  $name: "Roboto",
  $path: "https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBBc4.woff2",
  $weight: 300,
  $style: normal,
  $exts: woff2
);

@include font-face(
  $name: "Roboto",
  $path: "https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxK.woff2)",
  $weight: 500,
  $style: normal,
  $exts: woff2
);

@include font-face(
  $name: "Roboto",
  $path: "https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxK.woff2)",
  $weight: 700,
  $style: normal,
  $exts: woff2
);
*/
/*
# =================================================================
# HTMLタグ　共通設定
# =================================================================
*/
// フォントのアンチエイリアス設定
* {
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// htmlのフォント情報は_const.scssから設定する
html {
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  color: map-get($theme_colors, "blk");
  font-size: map-get($theme_size, "html");
}
// bodyのフォント情報はhtmlを継承する
body {
  @extend html;
  font: {
    family: map-get($theme_fonts, "primary");
    size: map-get($theme_size, "body");
    feature-settings: "pkna" 1;
  }
  background: none;
  &.preload * {
    transition: none !important;
  }
}

img {
  -ms-interpolation-mode: bicubic;
}

// h1〜h3タグのフォントサイズを設定
@each $header,
  $size
    in (
      h1: map-get($theme_size, "h1"),
      h2: map-get($theme_size, "h2"),
      h3: map-get($theme_size, "h3")
    )
{
  #{$header} {
    font-size: $size;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
a,
ul,
li,
p,
th,
td,
pre {
  padding: append(map-get($theme_size, "padding"), 0);
}
// hxタグとstrongタグは割り当てるフォントを変更する
h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font: {
    family: map-get($theme_fonts, "secondary");
    weight: bold;
  }
}
pre {
  white-space: pre-wrap;
  font: {
    family: map-get($theme_fonts, "secondary");
  }
}
// sectionとarticleはbeforeとafter擬似要素のクリアを事前にしておいてクリア忘れを防止する
section,
article {
  &::before,
  &::after {
    content: "";
    @include absolute(
      $positions: (
        0,
        0,
        0,
        0,
      )
    );
    pointer-events: none;
  }
}
// 下線などの装飾を付与しない
a,
ul,
p,
span {
  text-decoration: none;
}
// 要素の端で自動で折り返す
p {
  word-wrap: break-word;
}
/*
# =================================================================
# デザイン共通設定
# =================================================================
*/
// グローバルナビを表示した時のmainタグ要素の挙動
// すべて : 半透明にする
// IE・chrome以外 : ぼかす
main {
  &.is-open {
    opacity: 0.1;
  }
  &.is-blur {
    opacity: 0.6;
    filter: blur(6px);
  }
}

// 背景塗り潰し用のクラスを生成
// .cmn-bg-wht : 白色塗り潰し
// .cmn-bg-org : オレンジ塗り潰し
// .cmn-bg-blk : 黒色塗り潰し
// .cmn-bg-border : 縦ストライプボーダー + 黒半透明背景
@each $name,
  $color
    in (
      wht: map-get($theme_colors, "wht"),
      org: map-get($theme_colors, "org"),
      blk: map-get($theme_colors, "blk"),
      border: map-get($theme_colors, "blk")
    )
{
  .cmn-bg-#{$name} {
    @if $name != border {
      background-color: $color;
    } @else {
      background: rgba($color, 0.7)
        url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAYAAACddGYaAAAAD0lEQVQIW2NkQABjRmQOAAM+AGkQsDBSAAAAAElFTkSuQmCC)
        repeat;
    }
  }
}
// 描写域の設定クラスを生成する（横幅：ブラウザ画面の100%（親要素無視））
// .cmn-screen-full : ブラウザ画面の縦幅100%（親要素無視）
// .cmn-screen-harf : ブラウザ画面の縦幅50% （親要素無視）
// .cmn-screen : ブラウザ画面の縦幅は内包要素により決まる（指定する場合は別途設定が必要）
@each $name, $height in (full: 100vh, harf: 50vh, null: null) {
  @if $name != null {
    .cmn-screen-#{$name} {
      @include relative(
        $size: (
          $height,
          100%,
        )
      );
    }
  } @else {
    .cmn-screen {
      @include relative(
        $size: (
          null,
          100%,
        )
      );
    }
  }
}
// テキスト（ブロック要素）の表示位置の指定
// .cmn-left : 左寄せ
// .cmn-right : 右寄せ
// .cmn-center : 中央寄せ
@each $name in (left, right, center) {
  .cmn-#{$name} {
    text-align: #{$name};
  }
}

// テキスト（ブロック要素）の表示位置の指定
// .cmn-container : 上下左右中央寄せ
// ※親要素に対しての横幅・高さ
// ※親要素に対しての上下左右中央寄せ
.cmn-container {
  @include absolute(
    $size: (
      100%,
      100%,
    )
  );
  display: flex;
  flex-direction: column; /* 子要素をflexboxにより縦方向に揃える */
  justify-content: center; /* 子要素をflexboxにより中央に配置する */
  align-items: center; /* 子要素をflexboxにより中央に配置する */
}

.cmn-tags-right {
  background: map-get($theme_colors, "org");
  border-radius: 8px 0 0 8px;
  color: map-get($theme_colors, "wht");
  display: inline-block;
  @include relative(
    $size: (
      $tags_height,
      null,
    )
  );
  line-height: $tags_height;
  padding: 0 2em 0 3em;
  &::before {
    content: "";
    border: $tags_height_harf solid transparent;
    border-left-color: map-get($theme_colors, "org");
    border-right-width: 0;
    display: block;
    @include absolute(
      $positions: (
        0,
        ($tags_height_harf - 0.03) * -1,
        null,
        null,
      ),
      $size: (
        0,
        0,
      )
    );
  }
  &::after {
    content: "";
    background: map-get($theme_colors, "brz");
    border-radius: 8px 0 0 8px;
    display: block;
    @include absolute(
      $positions: (
        0,
        null,
        null,
        0,
      ),
      $size: (
        100%,
        1em,
      )
    );
  }
}
.cmn-tags-left {
  background: map-get($theme_colors, "org");
  border-radius: 0 8px 8px 0;
  color: map-get($theme_colors, "wht");
  display: inline-block;
  @include relative(
    $size: (
      $tags_height,
      null,
    )
  );
  line-height: $tags_height;
  padding: 0 3em 0 2em;
  &::before {
    content: "";
    border: $tags_height_harf solid transparent;
    border-right-color: map-get($theme_colors, "org");
    border-left-width: 0;
    display: block;
    @include absolute(
      $positions: (
        0,
        null,
        null,
        ($tags_height_harf - 0.03) * -1,
      ),
      $size: (
        0,
        0,
      )
    );
  }
  &::after {
    content: "";
    background: map-get($theme_colors, "brz");
    border-radius: 0 8px 8px 0;
    display: block;
    @include absolute(
      $positions: (
        0,
        0,
        null,
        null,
      ),
      $size: (
        100%,
        1em,
      )
    );
  }
}
